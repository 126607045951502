<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_USER_TIPS')" type="back" :settings="false" />
    </template>

    <div class="settingsinput-container">
      <div class="banner-holder" style="width: 100%;height: 100%;position: absolute;top: 0px;z-index: -1;">
        <!-- <img :src="require('@/assets/images/about_us.jpg')" style="width: 100%;height: 99%;" /> -->
        <div class="banner-content">
        <img :src="$h.getImage(banner?.Image, 'BANNER', null, false)" :alt="$t.getTranslation('LBL_BANNER')" style="width: 100%;height: 99%;" loading="lazy" />
        </div>
      </div>
      <div class="container">
        <div class="tips-content">
          <!-- <div class="bg-about">
              <img :src="require('@/assets/images/about_us.jpg')">
            </div> -->
          <!-- <div class="logo-about">
                <div class="logo-content">
                  <img :src="require('@/assets/images/about-logo.png')">
                </div>
                <div class="about-text">
                <p class="main">{{ $t.getTranslation("LBL_USER_TIPS") }}</p>
                </div>
            </div> -->
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, onMounted, defineAsyncComponent } from 'vue'
import { $HTTP } from '@/utils/axios'
import { post } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'
import { configs, extendedConfigs } from '@/utils/configs.js'
import { useStore } from '@/store'
import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'
// import ProfileNavigationComponent from '@/components/navigations/ProfileNavigationComponent.vue'

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: 'UserTipsPage',
  components: {
    ProfileNavigationComponent
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject('$translation')
    
    const store = useStore()
    
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const banner = ref({})

    const getData = async () => {
      try {
        //uncomment after test
        let res = await $HTTP.get(`/banner/list?Type=USERTIPSBANNER&page=1&size=1&LanguageCode=${currentLanguageCode.value}`)
        if (res?.data?.data?.data) {
          let data = res?.data?.data?.data
          banner.value = data[0]
        }

        return
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.catchError(err, true)
      }
    }

    onMounted(async () => {
      await getData()
    })

    const validate = (showNotifications) => {
      let validationRules = {}
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys]
        }
      }
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    return {
      banner
    }
  }
})
</script>
<style scoped>
.settingsinput-container .container {
  justify-content: center;
  padding: 0px 0px 0px;
  /* min-height: calc(100vh - 250px); */
}



.tips-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.banner-content {
  min-width: 100px;
  min-height: 100px;
  text-align: center;
  line-height: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  align-items: center;
  align-content: center;
  font-size: 18px;
  padding-top: 60px;
  padding-bottom: 70px;
}

.banner-holder{
    display: flex;
    justify-content: center;
}
</style>
